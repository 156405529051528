import type { FormInstance } from 'antd';
import { message } from 'antd';
import { action, observable } from 'mobx';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { API } from '../api';

interface LabelAndValue {
  label: string;
  value: string;
  disabled?: boolean;
}

interface resultValue<T> {
  code: number;
  data?: T;
  msg?: string;
}

export default class AdminEditorModel {
  @observable
  public visible = false;

  @observable
  public type = 'add';

  @observable
  public saveLoading = false; // 保存状态

  @observable public allRoleType: LabelAndValue[] = []; // 管理员列表

  @observable public allSex: LabelAndValue[] = [
    {
      label: '男',
      value: '1',
    },
    {
      label: '女',
      value: '0',
    },
    {
      label: '未知',
      value: '2',
      disabled: true,
    },
  ]; // 性别列表

  @observable public ref: FormInstance;

  @observable public id = null; // 用户id

  @observable public userName = null; // 用户名称-编辑时使用

  @observable public parent = null;

  constructor({ parent }) {
    this.parent = parent;
  }

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public onShow = (data?: any, type = 'add') => {
    this.type = type;
    this.visible = true;
    if (type !== 'add') {
      setTimeout(() => {
        for (const key in data) {
          if (typeof data[key] === 'number') {
            data[key] = data[key].toString();
          }
        }
        this.ref.setFieldsValue(data);
      }, 0);
      this.id = data.id;
      this.userName = data.userName;
    } else {
      setTimeout(() => {
        this.ref.setFieldsValue({ accountStatus: '1' });
      }, 0);
    }
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    this.saveLoading = true;
    const values = this.ref.getFieldsValue();
    let postParams = { ...values };
    if (this.type === 'edit') {
      postParams = {
        ...postParams,
        id: this.id,
        userName: this.userName,
      };
    }
    if (postParams.userRole) {
      postParams = {
        ...postParams,
        userRoleName: this.allRoleType?.filter((item) => item.value === postParams.userRole)?.[0]?.label,
      };
    }
    // 不选择性别默认值为2
    if (!postParams.userSex) {
      postParams.userSex = '2';
    }
    const apiUrl = this.type === 'add' ? API.saveAdmin : API.editAdmin;
    request<resultValue<any>>({
      url: apiUrl,
      method: 'POST',
      data: { ...postParams },
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.ref.resetFields();
        this.onClose();
        this.parent.pageStore.grid.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
