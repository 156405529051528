import { Button, Col, Form, Input, Modal, Radio, Row, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type ResetPasswordStore from './resetPasswordModel';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

// 重置密码
export const ResetPasswordModal = observer(({ store }: { store: ResetPasswordStore }): JSX.Element => {
  if (!store) {
    return null;
  }
  const { visible, onClose, saveLoading, userName, onSave, setRef } = store;

  return (
    <Modal
      centered={true}
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>
          <Button
            loading={saveLoading}
            onClick={onSave}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
      maskClosable={false}
      onCancel={onClose}
      open={visible}
      title={userName ? `将为「${userName}」重置密码` : '重置密码'}
      width={500}
    >
      <Form ref={setRef}>
        <Row>
          <Col span={24}>
            <Form.Item
              {...formItemLayout}
              label="新密码"
              name="password"
              validateTrigger="onBlur"
              rules={[{ required: true }]}
            >
              <Input.Password
                autoComplete="off"
                placeholder="请输入密码"
                maxLength={8}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
