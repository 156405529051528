export const API = {
  // 查询管理员
  queryAdminPage: '/pbb/platform/sys/user/list/page',
  // 新增管理员
  saveAdmin: '/pbb/platform/sys/user/add',
  // 编辑管理员
  editAdmin: '/pbb/platform/sys/user/edit',
  // 删除管理员
  deleteAdmin: '/pbb/platform/sys/user/delete',
  // 编辑管理员状态
  editAdminStatus: '/pbb/platform/sys/user/status',
  // 重置密码
  resetPassword: '/pbb/platform/sys/user/modify/password',
};
